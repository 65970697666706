import { AppProvider } from "@/lib/contexts/appContext"
import { CartProvider } from "@/components/woocommerce/cartStore"
import "../styles/app.css"

export default function App({ Component, pageProps }) {
  if (pageProps?.pathData) {
    return (
      <AppProvider pathData={pageProps.pathData}>
        <Component {...pageProps} />
      </AppProvider>
    )
  }

  return (
    <CartProvider>
      <Component {...pageProps} />
    </CartProvider>
  )
}

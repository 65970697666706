export function getProps(props, location) {
  let raw = props
    .filter((prop) => prop.location === location)
    .map((prop) => prop.content)

  if (!raw.length) {
    return {}
  }

  return raw[0]
}

export function getCopy(copy) {
  const data = {}

  copy.forEach((collection) => {
    data[collection.location] = collection.strings
  })

  return data
}

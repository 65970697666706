import {
  getVariationAttributes,
  getAttributes,
  getVariationMatchingSelectedAttributes
} from "@/lib/helpers/woocommerce"
import { decodeHTMLEntities } from "@/lib/helpers/utils"

export const pushDataLayer = (dataLayer, clearEcommerce = false) => {
  if (!dataLayer) {
    return
  }

  if (clearEcommerce) {
    clearEcom()
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(dataLayer)
}

/**
 * Send select_item event to dataLayer.
 *
 * @param {object} product Product data
 */
export const trackProductClick = (product) => {
  if (!product) {
    return
  }

  const dataLayer = {
    event: "select_item",
    ecommerce: {
      items: Array.isArray(product) ? product : [{ ...product }]
    }
  }

  clearEcom()

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(dataLayer)
}

export const trackCheckoutForm = (tracking) => {
  if (!tracking) {
    return
  }
  const allowedEvents = ["purchase"]

  Object.keys(tracking)
    .filter((key) => allowedEvents.includes(key))
    .map((key) => {
      pushDataLayer(
        {
          event: key,
          ecommerce: { ...tracking[key] }
        },
        true
      )
    })
}

/**
 * Clear ecommerce dataLayer object
 */
const clearEcom = () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ ecommerce: null })
}

// Web shop functions
const listName = "giftShop"

/**
 * Compare current product with previous cart to determine which event to fire and calculate quantity diff
 *
 * @param {object} product WC Product object
 * @param {object} cart WC Cart object
 */
export const gtmAddToCart = (product, cart) => {
  if (!product || !cart) {
    return
  }

  const previous = cart.find((item) => item.key === product.key)

  let { quantity: prevQuantity } = previous || {}
  let event =
    prevQuantity > product.quantity ? "remove_from_cart" : "add_to_cart"

  if (!previous) {
    prevQuantity = 0
    event = "add_to_cart"
  }

  const { name, id, prices, sku } = product

  const { price } = prices

  const diff = Math.abs(prevQuantity - product.quantity)

  const variant = product.variation
    .map(function (variant) {
      return variant.value
    })
    .join(",")

  pushDataLayer(
    {
      event,
      ecommerce: {
        items: [
          {
            item_name: decodeHTMLEntities(name),
            item_id: id,
            sku,
            price,
            quantity: diff,
            item_list_name: listName,
            item_variant: variant
          }
        ]
      }
    },
    true
  )
}

/**
 * Fire remove cart event and calculate removed quantity.
 *
 * @param {object} product WC Product object
 * @param {object} cart WC Cart object
 */
export const gtmRemoveFromCart = (product, cart) => {
  if (!product) {
    return
  }

  const previous = cart.find((item) => item.key === product.key)

  const { quantity: prevQuantity } = previous || {}

  const { name, id, prices, sku } = product

  const { price } = prices

  pushDataLayer(
    {
      event: "remove_from_cart",
      ecommerce: {
        items: [
          {
            item_name: decodeHTMLEntities(name),
            item_id: id,
            sku,
            price,
            quantity: prevQuantity,
            item_list_name: listName
          }
        ]
      }
    },
    true
  )
}

/**
 * Convert WC Products to GA4 Ecommerce objects
 *
 * @param {array} items Array with WC Product objects
 * @param {string} itemlistName
 * @returns array
 */
export const getProductResponse = (items, itemlistName = false) => {
  if (items === undefined) {
    return
  }

  const products = items.reduce((result, item, index) => {
    const { id, name, prices, sku, quantity } = item
    const { price } = prices || {}

    return [
      ...result,
      {
        index: index + 1,
        item_name: decodeHTMLEntities(name),
        item_id: id,
        price,
        sku,
        quantity,
        item_list_name: itemlistName || listName
      }
    ]
  }, [])

  return products
}

/**
 * Return WC Product object if matching variation is found
 *
 * @param {abject} product
 * @param {array} selectedAttributes
 * @returns {mixed} WC Product object or undefined
 */
export const getProductVariationfromAttributes = (
  product,
  selectedAttributes
) => {
  if (!product || !selectedAttributes) {
    return undefined
  }

  const attributes = getAttributes(product.attributes)
  const variationAttributes = getVariationAttributes(product.variations)

  const currentVariationID = getVariationMatchingSelectedAttributes(
    attributes,
    variationAttributes,
    selectedAttributes
  )

  const currentVariation = product.variations.find(
    (item) => item.id === currentVariationID
  )

  return currentVariation?.product
}

export const trackSearch = (phrase, context) => {
  if (typeof phrase === "string" && phrase.length === 0) {
    return
  }

  pushDataLayer({
    event: "search",
    search_term: phrase,
    context: context
  })
}

/**
 * Fire remove cart event and calculate removed quantity.
 *
 * @param {object} product WC Product object
 * @param {object} cart WC Cart object
 */
export const trackViewCart = (data) => {
  if (!data.items) {
    return
  }

  pushDataLayer(
    {
      event: "view_cart",
      ecommerce: {
        currency: data.totals.currency_code,
        value: data.totals.total_price,
        items: getProductResponse(data.items)
      }
    },
    true
  )
}
